<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Working from anywhere <span class="text_primary">guide</span>
                     </h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0"
                                 :to="{ name: 'user_home' }">
                                 Home
                              </router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Working From Anywhere</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <section :class="!company_code ? 'work_from_office how_else_ergo mb-0' : 'work_from_office how_else_ergo'">
         <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'hybrid' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere1.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                 <img src="../assets/images/home-essentials/hybrid.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Hybrid Working</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Working at home as well as the office? We’ve got you covered.
                                 </p>
                                 <p class="text_balticsea font_size_16 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'hybird_working_manage_your_schedule' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere2.png" alt="img" class="mb_66">

                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Your Schedule</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Get your schedule under control in 3 easy steps.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'second_setup' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere3.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                 <img src="../assets/images/home-essentials/second_setup.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">30-Second Setup</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    No matter where you work, set yourself up for success.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    3 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'mobility_kits' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere4.png" alt="img" class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Mobility Kits</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    It’s easy to stay comfortable wherever you work.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'staying_focused' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere6.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                 <img src="../assets/images/home-essentials/staying-focused.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Staying Focused</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Staying focused requires skill, patience and practice.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'anywhere_stretches' }">
                              <img src="../assets/images/work-from-office/work_from_anywhere5.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                                 <img src="../assets/images/stay-focused/stress_buster.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Anywhere Stretches</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Be kind to your body and take regular stretch breaks.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>
      <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-10">
                  <div class="ergonomic-image pt_26 pb_26 ">
                     <div class="overlay">
                        <div class="row">
                           <div class="col-md-12 col-lg-7 col-xl-6">
                              <div class="left-section pl_60 pr_60">
                                 <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment
                                 </h6>
                                 <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help
                                    you feel great, enjoy more energy and supercharge your productivity. </p>
                                 <div class="mt_14">
                                    <router-link :to="{ name: 'self_assessment_signup' }"
                                       class="btn btn_secondary font_size_16">Take a Self-Assessment
                                    </router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!--<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/661823065?h=376ae5a53e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Making Hybrid Work Successful"></iframe>
                   
                  </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/580346529?h=3561a2038c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ERGO_in 30 seconds"></iframe>
         </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/661820196?h=1ce90fa111&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="How to Stay Focused"></iframe>
         </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">

                  <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe src="https://player.vimeo.com/video/320059710?h=a3ee06102d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Stress Buster Series"></iframe>

                  </div>
               </div>

            </div>
         </div>
      </div> -->
   </div>
</template>

<script>
export default {
   data() {
      return {
         company_code: true,
      }
   },
   name: "Work_from_anywhere",
   mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      let data = this.$store.getters["auth/authUser"];
      let company_data = data.client;
      if(company_data.self_assessment_button_status == 0){
         this.company_code = false;
      }
      if (
         localStorage.getItem("userToken") == null ||
         localStorage.getItem("employeeEmail") == null
      ) {
         this.$router.push({ name: "login" }).catch(() => true);
      }
   },

}
</script>